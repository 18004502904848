import { Flex, Heading, HStack, Link, Stack, Text } from "@chakra-ui/react";
import React from "react";
import TeamIcon from "../../theme/icon/TeamIcon";
import LinkedInIcon from "../../theme/icon/Linkedin-icon";

function AdvisorCard({ name, avatar, position, description, idx, linkedin }) {
  return (
    <Flex w={["196px", "276px"]} border="2px solid #06A9B4">
      <Flex
        _hover={{
          border: "2px solid #06A9B4",
          transform: "translate(-12px, -12px)",
        }}
        bg="#000"
        px={["16px"]}
        pt={["0", "12px"]}
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        w={["196px", "276px"]}
        minH={["406px", "341px"]}
        flexDirection={["column"]}
        border="2px solid #06A9B400"
        transition="all .6s cubic-bezier(.165,.84,.44,1);"
      >
        <TeamIcon idx={idx} xlinkHref={avatar} />

        <Stack spacing="0px" pt={[0, "8px"]}>
          <Heading size="h4">{name}</Heading>

          <Text color="#06A9B4">{position}</Text>
          <HStack justifyContent="center">
            <Link
              isExternal
              href={linkedin}
              color="#06A9B4"
              _hover={{ color: "#fff" }}
            >
              <LinkedInIcon />
            </Link>
          </HStack>
        </Stack>
      </Flex>
    </Flex>
  );
}

export default AdvisorCard;
