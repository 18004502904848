import React from "react";
import ReactECharts from "echarts-for-react";
import { useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import * as echarts from 'echarts/core';

const TokenEChart = () => {
  const [isBigScreen] = useMediaQuery("(min-width: 480px)");
  const colorPalette = [
    '#06A9B4',
    '#46EACC',
    '#CE30DD',
    '#6338DB',
    "#9448F0",
    "#2925EE"
  ]
  echarts.registerTheme('my_theme', {
    color: colorPalette,
    graph: colorPalette
  });

  const option = {
    title: {
      text: "Total Supply",
      subtext: "1B",
      left: "center",
      top: "42%",
      textStyle: {
        fontFamily: `'ClashDisplay-Semibold', sans-serif`,
        fontSize: useBreakpointValue({ base: "18px", md: "20px" }),
        color: "#fff",
        fontWeight: 600,
      },
      subtextStyle: {
        fontFamily: `'ClashDisplay-Semibold', sans-serif`,
        fontSize: useBreakpointValue({ base: "32px", md: "50px" }),
        color: "#06a9b4",
        fontWeight: 600,
      },
    },
    tooltip: {
      show: false,
      textStyle: {
        width: 19,
      },
      trigger: "item",
      formatter: "{a} <br/>{b} : {c}M",
    },
    legend: { show: false },
    series: [
      {
        name: "Info:",
        type: "pie",
        radius: useBreakpointValue({
          base: ["50%", "88%"],
          md: ["40%", "70%"],
        }),
        data: [
          { value: 15, name: "Airdrop/Bounty/ Partners", color: "#fff" },
          { value: 15, name: "Public Sale" },
          { value: 30, name: "Staking Reward" },
          { value: 20, name: "Founding Team" },
          { value: 10, name: "Early Contributor" },
          { value: 10, name: "Treasury" },
        ],
        label: {
          show: isBigScreen ? true : false,

          overflow: "break",
          width: 190,
          height: 54,
          formatter: ({ value, name }) =>
            "{name|" + name + ": }\n{value|" + value + "0M}",
          rich: {
            name: {
              fontSize: 16,
              fontWeight: 500,
              lineHeight: 26,
              color: "#fff",
            },
            value: {
              fontSize: 16,
              fontWeight: "medium",
              lineHeight: 26,
              color: "#06a9b4",
            },

            x: {
              fontSize: 18,
              fontFamily: "Microsoft YaHei",
              borderColor: "#449933",
              borderRadius: 4,
            },
          },
        },
        labelLine: {
          show: isBigScreen ? true : false,
          length: 15,
          length2: 25,
          lineStyle: {
            width: 3,
          },
        },

        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        backgroundColor: "#faa",
      },
    ],
  };

  return (
    <ReactECharts
      // opts={{ renderer: "svg" }} // use svg to render the chart.
      option={option}
      theme='my_theme'
      style={{
        height: useBreakpointValue({ base: "330px", md: "550px" }),
        width: "100%",
        maxWidth: "800px",
      }}
    />
  );
};

export default TokenEChart;
