import { Icon } from "@chakra-ui/react";

export default function TwitterIcon({
  color = "#06A9B4",
  width = "19px",
  height = "19px",
}) {
  return (
    <Icon width={width} height={height} viewBox="0 0 19 19" fill="none">
      <path
        d="M16.3867 4.59375C17.0898 4.06641 17.7227 3.43359 18.2148 2.69531C17.582 2.97656 16.8438 3.1875 16.1055 3.25781C16.8789 2.80078 17.4414 2.09766 17.7227 1.21875C17.0195 1.64062 16.2109 1.95703 15.4023 2.13281C14.6992 1.39453 13.75 0.972656 12.6953 0.972656C10.6562 0.972656 9.00391 2.625 9.00391 4.66406C9.00391 4.94531 9.03906 5.22656 9.10938 5.50781C6.05078 5.33203 3.30859 3.85547 1.48047 1.64062C1.16406 2.16797 0.988281 2.80078 0.988281 3.50391C0.988281 4.76953 1.62109 5.89453 2.64062 6.5625C2.04297 6.52734 1.44531 6.38672 0.953125 6.10547V6.14062C0.953125 7.93359 2.21875 9.41016 3.90625 9.76172C3.625 9.83203 3.27344 9.90234 2.95703 9.90234C2.71094 9.90234 2.5 9.86719 2.25391 9.83203C2.71094 11.3086 4.08203 12.3633 5.69922 12.3984C4.43359 13.3828 2.85156 13.9805 1.12891 13.9805C0.8125 13.9805 0.53125 13.9453 0.25 13.9102C1.86719 14.9648 3.80078 15.5625 5.91016 15.5625C12.6953 15.5625 16.3867 9.97266 16.3867 5.08594C16.3867 4.91016 16.3867 4.76953 16.3867 4.59375Z"
        fill="currentColor"
      />
    </Icon>
  );
}
