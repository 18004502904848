import { Icon } from "@chakra-ui/react";

export default function StarIcon({
  color = "#06A9B4",
  width = "39px",
  height = "39px",
}) {
  return (
    <Icon
      className="lock-icon"
      width={width}
      height={height}
      viewBox="0 0 39 39"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4 0C25.2 16.129 22 13.093 39 18.595 22 24.098 25.2 20.872 19.4 37 13.6 20.871 17 24.097 0 18.595 17 13.093 13.6 16.13 19.4 0Z"
        fill={color}
      />
    </Icon>
  );
}
