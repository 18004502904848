import { Icon } from "@chakra-ui/react";

export default function ArrowIcon({ width = "24px", height = "24px" }) {
  return (
    <Icon
      className="lock-icon"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6 18 18 6M8.25 6H18v9.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
