import { Icon } from "@chakra-ui/react";

export default function TwitterIcon({
  color = "#06A9B4",
  width = "19px",
  height = "19px",
}) {
  return (
    <Icon width={width} height={height} viewBox="0 0 19 19" fill="none">
     <path d="M4.65625 18.0605V6.38086H1.02344V18.0605H4.65625ZM2.82031 4.81836C3.99219 4.81836 4.92969 3.8418 4.92969 2.66992C4.92969 1.53711 3.99219 0.599609 2.82031 0.599609C1.6875 0.599609 0.75 1.53711 0.75 2.66992C0.75 3.8418 1.6875 4.81836 2.82031 4.81836ZM18.2109 18.0605H18.25V11.6543C18.25 8.5293 17.5469 6.10742 13.875 6.10742C12.1172 6.10742 10.9453 7.08398 10.4375 7.98242H10.3984V6.38086H6.92188V18.0605H10.5547V12.2793C10.5547 10.7559 10.8281 9.31055 12.7031 9.31055C14.5781 9.31055 14.6172 11.0293 14.6172 12.3965V18.0605H18.2109Z" fill="#7AE7FF"/>
    </Icon>
  );
}
