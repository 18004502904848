import Marquee from "react-fast-marquee";
import React from "react";
import { Heading, useBreakpointValue } from "@chakra-ui/react";

function MarqueeText() {
  const heightRes = useBreakpointValue([70, 80]);
  const bottomRes = useBreakpointValue([-110, -80]);
  return (
    <Marquee
      gradient={false}
      style={{
        height: heightRes,
        background: "#06A9B4",
        position: "absolute",
        bottom: bottomRes,
      }}
    >
      {MARQUEE_TEXT_LIST.map((i) => (
        <Heading size="h3" color="black" px={["30px", "50px"]}>
          {i}
        </Heading>
      ))}
    </Marquee>
  );
}

export default MarqueeText;

const MARQUEE_TEXT_LIST = [
  "DragonSUI",
  "NFTs Marketplace",
  "Create your NFTs",
  "Create your collection",
  "List NFTs for sale",
];
