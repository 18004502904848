import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const fonts = {
  heading: `'ClashDisplay-Semibold', sans-serif`,
  body: `Poppins, sans-serif`,
};

const fontSizes = {
  "sm-mid": "0.9375rem",
  "2xl-mid": "1.75rem", // 28px
  "3xl-mid": "2rem", // 32px
  "4xl-mid": "2.5rem", // 40px
};
const styles = {
  global: {
    "*": {
      margin: 0,
      boxSizing: "border-box",
    },
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      margin: 0,
      padding: 0,
      bg: "#080E09",
      scrollbarWidth: "none",
    },
    "body::-webkit-scrollbar": {
      display: "none",
    },
    ".swiper-pagination-bullet": {
      width: "12px",
      height: "12px",
      margin: "auto 5px",
      background: "#f3f3f3",
    },
    ".swiper-pagination-bullet-active": {
      width: "14px",
      height: "14px",
      margin: "auto 5px",
      background: "black",
      border: "2px solid #06A9B4",
    },
    "#tsparticles .tsparticles-canvas": {
      height: "100%",
      width: "100%",
    },
  },
};

const components = {
  Heading: {
    baseStyle: {
      fontFamily: "heading",
      fontWeight: "semibold",
      lineHeight: 1.25,
    },
    sizes: {
      h1: {
        fontSize: ["3xl-mid", "6xl"],
      },
      h2: {
        fontSize: ["2xl-mid", "4xl-mid"],
      },
      h3: {
        fontSize: ["2xl", "3xl"],
      },
      h4: {
        fontSize: ["xl", "2xl"],
      },
      h5: {
        fontSize: ["lg", "lg"],
      },
    },
  },
  Text: {
    baseStyle: {
      fontFamily: "body",
      fontWeight: "medium",
    },
    sizes: {
      body1: {
        fontSize: "sm-mid",
        lineHeight: "26px",
        color: "#999",
      },
      body2: {
        fontSize: "lg",
        lineHeight: "28px",
        color: "#fff",
        fontWeights: "semibold",
      },
    },
    defaultProps: {
      size: "body1",
    },
  },
  Button: {
    baseStyle: {
      fontWeight: "bold",
    },
    sizes: {
      std: {
        fontSize: "md",
        lineHeight: "1.5",
        height: "3.125rem",
        padding: "0px 30px",
        borderRadius: "0rem",
        textTransform: "uppercase",
      },
    },
    variants: {
      outline: {
        bg: "transparent",
        borderColor: "transparent",
        _after: {
          content: "''",
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "full",
          height: "2px",
          borderRadius: "inherit",
          border: "1px solid #06A9B4",
          background: "#06A9B4",
          transform: "scaleX(1) translateZ(0);",
          transformOrigin: "right",
          transition: "transform .6s cubic-bezier(.165,.84,.44,1) .3s;",
        },
        _before: {
          content: "''",
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "100%",
          height: "2px",
          borderRadius: "inherit",
          border: "1px solid #06A9B4",
          background: "#06A9B4",
          transform: "scaleX(0) translateZ(0); ",
          transformOrigin: "left",
          transition: "transform .6s cubic-bezier(.165,.84,.44,1);",
        },
        _hover: {
          bg: "transparent",
          "&:after": {
            transform: "scaleX(0) translateZ(0);",
            transformOrigin: "right",
            transition: "transform .6s cubic-bezier(.165,.84,.44,1);",
          },
          "&:before": {
            transform: "scaleX(1) translateZ(0);",
            transformOrigin: "left",
            transition: "transform .6s cubic-bezier(.165,.84,.44,1) 0.3s;",
          },
        },
      },
      solid: (props) => ({
        bg: "#06A9B4",
        _hover: {
          bg: "#fff",
          color: "#080E09",
        },
      }),
    },
    defaultProps: {
      size: "std",
    },
  },
};

const theme = extendTheme({
  config,
  fonts,
  fontSizes,
  styles,
  components,
});

export default theme;
