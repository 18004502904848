import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Img,
  Link,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Element } from "react-scroll";
import { motion } from "framer-motion";

import ArrowIcon from "../theme/icon/ArrowIcon";
import Hero2Image from "../theme/images/hero2-image.png";
import Hero2Bg from "../theme/images/hero2-bg.png";
import Hero2BgMobile from "../theme/images/hero2-bg-mobile.png";
import Hero2IconFolder from "../theme/images/hero2-icon-folder.png";
import Hero2IconImage from "../theme/images/hero2-icon-image-square.png";
import Hero2IconTag from "../theme/images/hero2-icon-tag.png";
import Hero2IconWallet from "../theme/images/hero2-icon-wallet.png";

import StarIcon from "../theme/icon/StarIcon";
import MarqueeText from "./MarqueeText";

const Hero2 = () => {
  const Hero2BgUrl = useBreakpointValue({ base: Hero2BgMobile, md: Hero2Bg });

  const marginTop = useBreakpointValue({ base: "-4px", md: "-30px" });

  // star1 to nhất
  const starStyle1 = useBreakpointValue({
    base: {
      position: "absolute",
      bottom: "50%",
      left: "-10%",
    },
    md: {
      position: "absolute",
      bottom: "30%",
      right: "-6%",
    },
  });

  // start3 nhỏ nhất
  const starStyle3 = useBreakpointValue({
    base: {
      position: "absolute",
      top: "-50px",
      right: "0%",
    },
    md: {
      position: "absolute",
      top: "-60px",
      right: "10%",
    },
  });

  return (
    <VStack position="relative">
      <Flex
        w="full"
        h="full"
        zIndex="-1"
        top={["45px", "84px"]}
        position="absolute"
        align="center"
        overflow="hidden"
        id="AIRDROP/BOUNTY"
      >
        <Image
          w="full"
          h="full"
          src={Hero2BgUrl}
          alt="Hero2BgUrl"
          // objectFit="cover"
        />
      </Flex>

      <Flex
        w="full"
        maxW="1440px"
        px={["20px", "115px"]}
        pt={["30px", "44px"]}
        pb={["40px", "119px"]}
        flexDirection={["column", "column"]}
      >
        <Stack
          textAlign={["center", "left"]}
          justifyContent={"center"}
          alignItems="center"
        >
          <motion.div
            id="image-hero2-image"
            animate={{
              y: [1, -3, 2, 1],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              repeatType: "reverse",
              curve: [0.42, 0, 0.58, 1],
            }}
          >
            <Img
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="500"
              src={Hero2Image}
              alt="Hero2Image"
              objectFit="cover"
              h={["200px", "256px"]}
              w={["229px", "294px"]}
            />
          </motion.div>
          <VStack
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
            style={{
              marginTop: marginTop,
            }}
          >
            <Heading
              size="h1"
              fontWeight="normal"
              textAlign="center"
              fontFamily="ClashDisplay-Regular"
            >
              Airdrop/Bounty
            </Heading>

            <Heading lineHeight={1.2} size="h1" color="#06A9B4">
              Programs
            </Heading>

            <Text
              maxW={["full", "360px"]}
              textAlign="center"
              color="fff"
              fontSize={["md", "lg"]}
              fontWeight="semibold"
              lineHeight={1.55}
            >
              Follow{" "}
              <Link
                textDecoration="underline"
                color="#06A9B4"
                href="https://twitter.com/dragonsui_com"
                isExternal
              >
                Our Twitter
              </Link>{" "}
              & Join{" "}
              <Link
                textDecoration="underline"
                color="#06A9B4"
                href="https://t.me/dragon_sui"
                isExternal
              >
                Our Telegram
              </Link>{" "}
              For Latest Updates
            </Text>
          </VStack>
        </Stack>

        <Flex
          w="full"
          pt={["40px", "147px"]}
          flexDirection={["column-reverse", "row"]}
          id="Create"
        >
          <Flex
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="500"
            pt={["46px", 0]}
            w={["full", "50%"]}
            alignItems="start"
            justifyContent="center"
            minH="752px"
          >
            <SimpleGrid
              p={["0px", "12px"]}
              w="full"
              h="full"
              columns={[1, 2]}
              spacingX={["0px", "40px"]}
              spacingY={["20px", "0px"]}
            >
              <Flex
                minW={["full", "278px"]}
                mb={["0px", "60px"]}
                border="2px solid #06A9B4"
              >
                <Flex
                  bg="#000"
                  _hover={{
                    border: "2px solid #06A9B4",
                    transform: "translate(-16px, -12px)",
                  }}
                  transition="all 0.3s ease-in-out"
                  flexDirection={["row", "column"]}
                  alignItems="start"
                  px={["12px", "20px"]}
                  py={["26px", "40px"]}
                  border="2px solid #06A9B400"
                  minH={["166px", "306px"]}
                  minW={["full", "274px"]}
                >
                  <Img
                    src={Hero2IconWallet}
                    alt="Hero2IconWallet"
                    objectFit="cover"
                    h={["60px", "60px"]}
                    w={["60px", "60px"]}
                  />{" "}
                  <Stack pt={[0, "24px"]} pl={["14px", 0]}>
                    <Heading size="h4">Connect your wallet</Heading>
                    <Text>
                      DragonSUI supports SUI Wallet (Sui Wallet Browser Chrome
                      Extension)
                    </Text>
                  </Stack>
                </Flex>
              </Flex>

              <Flex
                minW={["full", "278px"]}
                mt={["0px", "60px"]}
                border="2px solid #06A9B4"
              >
                <Flex
                  bg="#000"
                  _hover={{
                    border: "2px solid #06A9B4",
                    transform: "translate(-16px, -12px)",
                  }}
                  transition="all 0.3s ease-in-out"
                  flexDirection={["row", "column"]}
                  alignItems="start"
                  px={["12px", "20px"]}
                  py={["26px", "40px"]}
                  border="2px solid #06A9B400"
                  minH={["170px", "306px"]}
                  minW={["full", "274px"]}
                >
                  <Img
                    src={Hero2IconFolder}
                    alt="Hero2IconFolder"
                    objectFit="cover"
                    h={["60px", "60px"]}
                    w={["60px", "60px"]}
                  />{" "}
                  <Stack pt={[0, "24px"]} pl={["14px", 0]}>
                    <Heading size="h4">Create your collection</Heading>
                    <Text>
                      Add social links, a description, profile and banner
                      images, and set a secondary sales fee
                    </Text>
                  </Stack>
                </Flex>
              </Flex>

              <Flex
                minW={["full", "278px"]}
                mb={["0px", "60px"]}
                border="2px solid #06A9B4"
              >
                <Flex
                  bg="#000"
                  _hover={{
                    border: "2px solid #06A9B4",
                    transform: "translate(-16px, -12px)",
                  }}
                  transition="all 0.3s ease-in-out"
                  flexDirection={["row", "column"]}
                  alignItems="start"
                  px={["12px", "20px"]}
                  py={["26px", "40px"]}
                  border="2px solid #06A9B400"
                  minH={["170px", "306px"]}
                  minW={["full", "274px"]}
                >
                  <Img
                    src={Hero2IconImage}
                    alt="Hero2IconImage"
                    objectFit="cover"
                    h={["60px", "60px"]}
                    w={["60px", "60px"]}
                  />{" "}
                  <Stack pt={[0, "24px"]} pl={["14px", 0]}>
                    <Heading size="h4">Create your NFTs</Heading>
                    <Text>
                      Upload your work (image, video, audio, or 3D art),a title
                      description, and customize your NFTs with properties,
                      stats, & unlockable content.
                    </Text>
                  </Stack>
                </Flex>
              </Flex>

              <Flex
                minW={["full", "278px"]}
                mt={["0px", "60px"]}
                border="2px solid #06A9B4"
              >
                <Flex
                  bg="#000"
                  _hover={{
                    border: "2px solid #06A9B4",
                    transform: "translate(-16px, -12px)",
                  }}
                  transition="all 0.3s ease-in-out"
                  flexDirection={["row", "column"]}
                  alignItems="start"
                  px={["12px", "20px"]}
                  py={["26px", "40px"]}
                  border="2px solid #06A9B400"
                  minH={["170px", "306px"]}
                  minW={["full", "274px"]}
                >
                  <Img
                    src={Hero2IconTag}
                    alt="Hero2IconTag"
                    objectFit="cover"
                    h={["60px", "60px"]}
                    w={["60px", "60px"]}
                  />{" "}
                  <Stack pt={[0, "24px"]} pl={["14px", 0]}>
                    <Heading size="h4">List NFTs for sale</Heading>
                    <Text>
                      Choose the NFT you want to sell and we help you sell them.
                      buyers can bid for the NFT or simply buy at fixed-price
                    </Text>
                  </Stack>
                </Flex>
              </Flex>
            </SimpleGrid>
          </Flex>

          <Spacer />

          <Box
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
            w={["full", "50%"]}
            pl={["0px", "100px"]}
            pt={["60px", "0px"]}
          >
            <Stack
              my="auto"
              h="full"
              textAlign={["center", "left"]}
              justifyContent={"center"}
              alignItems={["center", "start"]}
            >
              <Heading
                size="h1"
                position="relative"
                fontWeight="normal"
                fontFamily="ClashDisplay-Regular"
              >
                Create And{" "}
                <Text
                  as="span"
                  fontSize="6xl"
                  color="#06a9b4"
                  display={["none", "inline-flex"]}
                  fontFamily="ClashDisplay-SemiBold"
                >
                  Sell
                </Text>{" "}
                <Heading size="h1" color="#06A9B4">
                  <Text
                    as="span"
                    fontSize={["3xl-mid", "6xl"]}
                    color="#06a9b4"
                    display={["inline-flex", "none"]}
                    fontFamily="ClashDisplay-SemiBold"
                  >
                    Sell
                  </Text>{" "}
                  Your NFTs
                </Heading>
                {/* Star3 nho nhat */}
                <motion.div
                  id="image-star3"
                  style={starStyle3}
                  animate={{
                    scale: [1, 1, 1, 1],
                    y: [2, 1, 0, 2],
                  }}
                  transition={{
                    duration: 3,
                    curve: [0.42, 0, 0.58, 1],
                    repeat: Infinity,
                    repeatType: "reverse",
                  }}
                >
                  <StarIcon
                    width={["25px", "39px"]}
                    height={["24px", "37px"]}
                  />
                </motion.div>
                {/* Star 1 to nhat */}
                <motion.div
                  id="image-star1"
                  style={starStyle1}
                  animate={{
                    scale: [1, 1, 1, 1],
                    y: [1, 0, 2, 0],
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse",
                    curve: [0.42, 0, 0.58, 1],
                  }}
                >
                  <StarIcon
                    color="#9448F0"
                    width={["21px", "29px"]}
                    height={["20px", "27px"]}
                  />
                </motion.div>
              </Heading>

              <Text
                maxW={["280px"]}
                textAlign={["center", "left"]}
                color="fff"
                fontSize={["md", "lg"]}
                fontWeight="semibold"
                lineHeight={1.55}
              >
                Discover, collect, and sell extraordinary NFTs
              </Text>

              <Stack
                display={["none", "flex"]}
                pt={["8px", "36px"]}
                pb={["28px", "58px"]}
              >
                <Button
                  maxH="40px"
                  maxW="250px"
                  variant="outline"
                  mx={["auto", "unset"]}
                  rightIcon={<ArrowIcon />}
                >
                  <Link href="https://devnet.dragonsui.com/" isExternal>
                    Test Staking in Devnet
                  </Link>
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Flex>

        <Stack display={["flex", "none"]} pt={["18px"]} pb={["20px"]}>
          <Button
            maxH="40px"
            maxW="250px"
            variant="outline"
            mx={["auto", "unset"]}
            rightIcon={<ArrowIcon />}
          >
            <Link href="https://devnet.dragonsui.com/" isExternal>
              Test Staking in Devnet
            </Link>
          </Button>
        </Stack>
      </Flex>

      <MarqueeText />
    </VStack>
  );
};

export default Hero2;
