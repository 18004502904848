import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  Tag,
  HStack,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";

import Logo from "../theme/images/Logo.svg";
import SearchIcon from "../theme/icon/SearchIcon";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {Link} from 'react-scroll'

import { useWallet } from "@mysten/wallet-adapter-react";

export default function NavBar() {
  const { onClose, isOpen, onToggle } = useDisclosure();

  const [scroll, setScroll] = useState(false);

  const changeScroll = () => {
    document.body.scrollTop > 60 || document.documentElement.scrollTop > 60
      ? setScroll(true)
      : setScroll(false);
  };

  window.addEventListener("scroll", changeScroll);

  return (
    <Box
      style={{ marginTop: "0px" }}
      w="full"
      position={scroll ? "fixed" : "sticky"}
      zIndex="sticky"
      backdropFilter={scroll ? "auto" : ""}
      backdropBlur="2px"
      bg={scroll ? "rgba(0, 0, 0, 0.75)" : "rgba(0, 0, 0, 0)"}
    >
      <Flex
        h={["57.25px", "79px"]}
        pt={["18px", "29px"]}
        px={["20px", "50px"]}
        align={"center"}
      >
        <Flex
          h="31.25px"
          alignItems="center"
          flex={{ base: 1, md: "auto" }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            h="15px"
            w="20px"
            onClick={onToggle}
            icon={
              isOpen ? (
                <CloseIcon w={3} h={3} mx="auto" />
              ) : (
                <HamburgerIcon w={5} h={5} />
              )
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>

        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Image
            minW={{base: "160px", md: "135px"}}
            src={Logo}
            height={["31.25px", "42px"]}
            alt="dragon-sui-logo"
          />

          <Flex
            flex={{ base: 1 }}
            display={{ base: "none", md: "flex" }}
            justifyContent="center"
          >
            <DesktopNav />
          </Flex>
        </Flex>

        {/* <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction="row"
          alignItems="center"
          spacing="27px"
        >
          <SearchIcon
            cursor="pointer"
            w={["20px", "24px"]}
            h={["20px", "24px"]}
          />

          <WalletConnect />
        </Stack> */}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav isOpen={isOpen} onClose={onClose} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      justifyContent="center"
      spacing={4}
    >
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                to={navItem.label}
              >
                <Text
                  _hover={{
                    color: "#06A9B4",
                  }}
                  fontWeight="semibold"
                  color="#FFF"
                  p={2}
                  mx="4px"
                  textTransform="uppercase"
                  cursor={"pointer"}
                >
                  {navItem.label}
                </Text>
              </Link>
            </PopoverTrigger>

            {/* {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )} */}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = ({ onClose, isOpen }) => {
  return (
    <>
      <Drawer
        size="full"
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        zIndex="popover"
      >
        <DrawerOverlay />

        <DrawerContent bg="#080E09">
          <DrawerHeader bg="transparent">
            <Flex minH="41px" justifyContent="center" alignItems="end">
              <DrawerCloseButton left="14px" top="26px" borderRadius="0" />
              <Image
                mx="auto"
                src={Logo}
                minW="140px"
                height={["34.25px", "42px"]}
                alt="dragon-sui-logo"
              />{" "}
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            {NAV_ITEMS.map((navItem) => (
              <MobileNavItem key={navItem.label} {...navItem} />
            ))}
            {/* <Flex flexDirection="column">
              <Link mb="5">About</Link>
              <Link>More Apps</Link>
            </Flex> */}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();
  console.log(label, children);
  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
      >
        <Link to={label} >
        <Text
          _hover={{
            textDecoration: "none",
            color: "#06A9B4",
          }}
          color="white"
        >
          {label}
        </Text>
        </Link>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link to={label} >
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "AIRDROP/BOUNTY",
    href: "#",
  },
  {
    label: "Create",
  },
  {
    label: "Roadmap",
    href: "#",
  },
  {
    label: "Tokenomics",
    href: "#",
  },
  {
    label: "TEAM & ADVISORS",
    href: "#",
  },
  {
    label: "SUBSCRIBE",
    href: "#",
  },
];

const WalletConnect = () => {
  const { select, wallet, connected, connecting, disconnect, getAccounts } =
    useWallet();

  const [account, setAccount] = useState(null);

  useEffect(() => {
    if (!connected || !wallet) return;

    const fetchAccInfo = async function () {
      const accounts = await getAccounts();

      setAccount(accounts[0]);
    };

    fetchAccInfo();
  }, [connected, getAccounts, wallet]);

  return (
    <>
      {connected ? (
        <HStack spacing={"5px"}>
          <Tag
            justifyContent="center"
            h="40px"
            size="xl"
            minW="165px"
            display={{ base: "none", md: "flex" }}
          >
            Acct:{" "}
            {`${account?.slice(0, 5)} ... ${account?.slice(
              account?.length - 4,
              account?.length
            )}`}
          </Tag>

          <Button
            width="40px"
            borderRadius="0"
            size="md"
            onClick={() => disconnect()}
          >
            <ExternalLinkIcon />
          </Button>
        </HStack>
      ) : (
        <Button
          w="210px"
          onClick={() => {
            if (!window.suiWallet) {
              return toast.error("It seems Sui Wallet is not installed.");
            }

            if (!connected) {
              select("Sui Wallet");
              // select("Suiet");
            } else {
              disconnect();
            }
          }}
          disabled={connecting}
          display={{ base: "none", md: "flex" }}
        >
          {connecting
            ? "connecting"
            : connected
            ? "Disconnect"
            : "wallet connect"}{" "}
        </Button>
      )}
    </>
  );
};
