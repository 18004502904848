import { Icon } from "@chakra-ui/react";

export default function RocketIcon({
  color = "#06A9B4",
  width = "56px",
  height = "56px",
}) {
  return (
    <Icon
      className="lock-icon"
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
    >
      <path
        opacity=".2"
        d="m16.666 24.369-6.869 8.225a1.662 1.662 0 0 0-.35 1.487l2.69 12.185a1.751 1.751 0 0 0 2.8.984L21.5 42c-3.85-6.694-5.096-12.578-4.834-17.631Zm23.537-.154 6.868 8.247a1.663 1.663 0 0 1 .35 1.488l-2.69 12.162a1.75 1.75 0 0 1-2.8 1.006l-6.563-5.25c3.85-6.715 5.097-12.6 4.834-17.653Z"
        fill="#9448F0"
      />
      <path
        d="M32 49h-7m2.406-44.669C23.25 7.656 9.36 20.956 21.5 42h14c11.9-21.044-1.794-34.322-5.906-37.669a1.706 1.706 0 0 0-2.188 0Z"
        stroke="#9448F0"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="m16.666 24.369-6.869 8.225a1.662 1.662 0 0 0-.35 1.487l2.69 12.185a1.751 1.751 0 0 0 2.8.984L21.5 42m18.703-17.806 7 8.4a1.662 1.662 0 0 1 .35 1.487l-2.69 12.184a1.75 1.75 0 0 1-2.8.985L35.5 42"
        stroke="#9448F0"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.5 23.625a2.625 2.625 0 1 0 0-5.25 2.625 2.625 0 0 0 0 5.25Z"
        fill="#9448F0"
      />
    </Icon>
  );
}
