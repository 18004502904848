import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Img,
  Link,
  Spacer,
  Square,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { motion } from "framer-motion";

import ArrowIcon from "../theme/icon/ArrowIcon";
import Image01 from "../theme/images/hero-image-02.png";
import HeroScroll from "../theme/images/hero-scroll.svg";
import HeroScrollText from "../theme/images/hero-scroll-text.svg";

import StarIcon from "../theme/icon/StarIcon";

const Hero = () => {
  // star1  to nhất
  const starStyle1 = useBreakpointValue({
    base: {
      position: "absolute",
      top: "10px",
      right: "14%",
      width: "22x",
      height: "22px",
    },
    md: {
      position: "absolute",
      bottom: "-50px",
      right: "32%",
      width: "32x",
      height: "32px",
    },
  });

  // start2
  const starStyle2 = useBreakpointValue({
    base: {
      position: "absolute",
      bottom: "-35px",
      left: "8%",
      width: "20x",
      height: "20px",
    },
    md: {
      position: "absolute",
      top: "10px",
      right: "40%",
      width: "25x",
      height: "25px",
    },
  });

  // start3 nhỏ nhất
  const starStyle3 = useBreakpointValue({
    base: {
      position: "absolute",
      top: "5px",
      left: "18%",
      width: "16x",
      height: "16px",
    },
    md: {
      position: "absolute",
      top: "30px",
      right: "12%",
    },
  });

  return (
    <Flex
      w="full"
      maxW="1440px"
      minHeight="640px"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
      }}
      px={["20px", "115px"]}
      pt={["40px", "147px"]}
      flexDirection={["column-reverse", "row"]}
    >
      <Box
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="500"
        mr={["0", "24px"]}
        w={["full", "50%"]}
        pt={["54px", "30px"]}
      >
        <Stack
          dataAos="fade-up"
          dataAosDelay="200"
          textAlign={["center", "left"]}
          justifyContent={"center"}
        >
          <Heading
            size="h1"
            position="relative"
            fontWeight="normal"
            fontFamily="ClashDisplay-Regular"
          >
            Discover, <br />
            Create, Collect &{" "}
            <motion.div
              id="image-star3"
              style={starStyle3}
              animate={{
                scale: [1, 1, 1, 1],
                y: [2, 1, 0, 2],
              }}
              transition={{
                duration: 3,
                curve: [0.42, 0, 0.58, 1],
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              <StarIcon color="#06A9B4" width="16px" height="16px" />
            </motion.div>
            <motion.div
              id="image-star2"
              style={starStyle2}
              animate={{
                scale: [1, 1, 1, 1],
                y: [0, 1, 0, 3],
              }}
              transition={{
                duration: 3,
                curve: [0.42, 0, 0.58, 1],
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              <StarIcon
                color="#fff"
                width={["20px", "25px"]}
                height={["20px", "25px"]}
              />
            </motion.div>
            <motion.div
              id="image-star1"
              style={starStyle1}
              animate={{
                scale: [1, 1, 1, 1],
                y: [1, 0, 2, 0],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse",
                curve: [0.42, 0, 0.58, 1],
              }}
            >
              <StarIcon
                color="#9448F0"
                width={["22px", "32px"]}
                height={["22px", "32px"]}
              />
            </motion.div>
          </Heading>

          <Heading size="h1" color="#06A9B4">
            Trade NFTs
          </Heading>

          <Text pt={["10px", "24px"]} maxW="324px">
            Get quick & easy access to digital collectibles, explore & trade
            NFTs from different collections & artists.
          </Text>

          <Stack pt={["8px", "36px"]} pb={["28px", "58px"]}>
            <Button
              maxH="40px"
              maxW="250px"
              variant="outline"
              mx={["auto", "unset"]}
              rightIcon={<ArrowIcon />}
            >
              <Link href="https://devnet.dragonsui.com/" isExternal>
                Test Staking in Devnet
              </Link>
            </Button>
          </Stack>

          <Stack>
            <Square
              position="relative"
              mx={["auto", "unset"]}
              size={["82px", "117px"]}
            >
              <Image src={HeroScroll} alt="HeroScroll" />
              <motion.div
                style={{
                  position: "absolute",
                  width: "80%",
                  height: "100%",
                  top: "0%",
                  bottom: "0%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                animate={{ rotate: 360 }}
                transition={{
                  repeat: Infinity,
                  duration: 10,
                  repeatType: "loop",
                  repeatDelay: 0,
                  ease: "linear",
                }}
              >
                <Image src={HeroScrollText} alt="HeroScrollText" />
              </motion.div>
            </Square>
          </Stack>
        </Stack>
      </Box>

      <Spacer />

      <Flex
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="500"
        w={["full", "50%"]}
        alignItems="start"
        justifyContent="center"
      >
        <motion.div
          id="image-hero1"
          animate={{
            y: [1, -3, 2, 1],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            repeatType: "reverse",
            curve: [0.42, 0, 0.58, 1],
          }}
        >
          <Img
            src={Image01}
            alt="Image01"
            objectFit="cover"
            h={["288px", "full"]}
            w={["252px", "full"]}
          />
        </motion.div>
      </Flex>
    </Flex>
  );
};

export default Hero;
