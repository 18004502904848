import React from "react";
import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";

import { SuiWalletAdapter } from "@mysten/wallet-adapter-sui-wallet";
import { SuietWalletAdapter } from "@suiet/wallet-adapter";

import { WalletProvider } from "@mysten/wallet-adapter-react";

import { ChakraProvider } from "@chakra-ui/react";

import theme from "./theme/theme";

import App from "./App";

const supportedWallets = [
  { adapter: new SuiWalletAdapter() },
  { adapter: new SuietWalletAdapter() },
];

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Toaster
        position="bottom-left"
        reverseOrder={true}
        toastOptions={{
          style: {
            borderRadius: 0,
            padding: "8px",
            background: "#06A9B4",
            color: "#080E09",
          },
        }}
      />
      <WalletProvider supportedWallets={supportedWallets}>
        <App />
      </WalletProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
