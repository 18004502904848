import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Show,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { motion } from "framer-motion";

import FooterBg from "../theme/images/footer-bg.png";
import FooterBgMobile from "../theme/images/footer-bg-mobile.png";
import StarIcon from "../theme/icon/StarIcon";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import Logo from "../theme/images/Logo.svg";
import DiscordIcon from "../theme/icon/DiscordIcon";
import TelegramIcon from "../theme/icon/TelegramIcon";
import TwitterIcon from "../theme/icon/TwitterIcon";

const Footer = () => {
  const [emailSubscribed, setEmailSubscribed] = useState("");
  const FooterBgUrl = useBreakpointValue({
    base: FooterBgMobile,
    md: FooterBg,
  });

  const onClickHandler = (e) => {
    e.preventDefault();

    const submitPOST = async () => {
      if (!emailSubscribed) return toast.error("Please fill your email!");

      const reg =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{3,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!reg.test(String(emailSubscribed).toLowerCase())) {
        return toast.error(`${emailSubscribed} is not a valid email address.`);
      }

      try {
        const data = { email: emailSubscribed };

        // eslint-disable-next-line no-undef
        Pageclip.send(
          "jp0X0Mmqr8zE3lySJS8InIDRy2SxQP2l",
          "subscribe",
          data,
          function (error, res) {
            if (error) return toast.error("Please try again!");

            if (res.data === "ok") {
              toast.success("Thank you. Submit successful!");
              setEmailSubscribed("");
            }
          }
        );
      } catch (error) {
        console.log("error", error);
      }
    };
    submitPOST();
  };
  // star1 to nhất
  const starStyle1 = useBreakpointValue({
    base: {
      position: "absolute",
      bottom: "20%",
      right: "-25%",
    },
    md: {
      position: "absolute",
      top: "20%",
      right: "-10%",
    },
  });

  // start3 nhỏ nhất
  const starStyle3 = useBreakpointValue({
    base: {
      position: "absolute",
      top: "0px",
      left: "-40%",
    },
    md: {
      position: "absolute",
      top: "0px",
      left: "-12%",
    },
  });

  return (
    <VStack
      overflow="hidden"
      position="relative"
      pt={["20px", "70px"]}
      id="SUBSCRIBE"
    >
      <Flex
        w="full"
        h="full"
        zIndex="-1"
        top={["185px", "70px"]}
        position="absolute"
        align="center"
        overflow="hidden"
      >
        <Image
          w="full"
          h="full"
          src={FooterBgUrl}
          alt="FooterBg"
          // objectFit="cover"
        />
      </Flex>

      <Flex
        w="full"
        maxW="1440px"
        px={["20px", "115px"]}
        pt={["30px", "44px"]}
        pb={["40px", "129px"]}
        flexDirection={["column", "column"]}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Stack
          my="auto"
          textAligns="center"
          justifyContent="center"
          alignItems="center"
        >
          <Heading
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="500"
            size="h1"
            position="relative"
            fontWeight="normal"
            fontFamily="ClashDisplay-Regular"
          >
            Join Our{" "}
            <Heading size="h1" color="#06A9B4">
              Community{" "}
            </Heading>
            {/* Star3 nho nhat */}
            <motion.div
              id="image-star3"
              style={starStyle3}
              animate={{
                scale: [1, 1, 1, 1],
                y: [2, 1, 0, 2],
              }}
              transition={{
                duration: 3,
                curve: [0.42, 0, 0.58, 1],
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              <StarIcon width={["123px", "39px"]} height={["21px", "37px"]} />
            </motion.div>
            {/* Star 1 to nhat */}
            <motion.div
              id="image-star1"
              style={starStyle1}
              animate={{
                scale: [1, 1, 1, 1],
                y: [1, 0, 2, 0],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse",
                curve: [0.42, 0, 0.58, 1],
              }}
            >
              <StarIcon
                color="#9448F0"
                width={["19px", "29px"]}
                height={["19px", "27px"]}
              />
            </motion.div>
          </Heading>

          <Text
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="500"
            pt={["4px", "18px"]}
            maxW={["330px"]}
            color="fff"
            fontSize={["lg"]}
            fontWeight="semibold"
            lineHeight={1.55}
          >
            Let’s make a great impact together{" "}
          </Text>

          <Stack pt={["8px", "46px"]} pb={["28px", "58px"]}>
            <InputGroup
              minW={["335px", "552px"]}
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              mb={("3px", "12px")}
              mx="auto"
              maxW={["335px", "572px"]}
              w="full"
              bg="06A9B4"
              h={["50px"]}
              color="white"
              border="2px solid #06A9B4"
              borderRadius="0"
            >
              <Input
                _focus={{ background: "#080E09" }}
                _active={{ background: "#080E09" }}
                bg="#080E09"
                h={["46px"]}
                autoComplete="off"
                type="email"
                name="email"
                id="email"
                required
                value={emailSubscribed}
                onChange={({ target }) => setEmailSubscribed(target.value)}
                variant="unstyled"
                my={1}
                pl={5}
                placeholder="Enter your email"
                _placeholder={{ color: "#fff" }}
                borderRadius="0"
              />
              <Show above="sm">
                <InputRightElement
                  spinner={<BeatLoader size={8} color="white" />}
                  onClick={onClickHandler}
                  cursor="pointer"
                  bg="#06A9B4"
                  h="full"
                  fontWeight="bold"
                  fontSize={["md"]}
                  w={["150px"]}
                  _hover={{ color: "#080E09", bg: "#fff" }}
                  transition="all .6s cubic-bezier(.165,.84,.44,1);"
                >
                  SUBSCRIBE
                </InputRightElement>
              </Show>
            </InputGroup>
            <HStack
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              display={["flex", "none"]}
              justifyContent="center"
            >
              <Button
                h="50px"
                spinner={<BeatLoader size={8} color="white" />}
                onClick={onClickHandler}
                cursor="pointer"
                bg="#06A9B4"
                fontWeight="bold"
                fontSize={["md"]}
                w={["150px"]}
              >
                subscribe
              </Button>
            </HStack>
          </Stack>
        </Stack>
      </Flex>

      <Flex
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="500"
        py="40px"
        w="full"
        maxW="1440px"
        px={["20px", "120px"]}
        alignItems="start"
        justifyContent="center"
        textAlign="center"
      >
        <SimpleGrid
          w="full"
          spacing={8}
          templateColumns={{ sm: "1fr 1fr", md: "6fr 2fr 1.1fr" }}
        >
          <Stack
            minH={["100px", "214px"]}
            h="full"
            justifyContent="space-between"
            textAlign="left"
            spacing={["24px", "24px"]}
          >
            <Box>
              <Image
                src={Logo}
                height={["31.25px", "42px"]}
                alt="dragon-sui-logo"
              />{" "}
            </Box>

            <Text maxW="360px">
              The leading NFT Marketplace on Sui Blockchain. Home of the next
              generation digital creators. Discover the best NFT collections.{" "}
            </Text>

            <HStack
              pt={[0, "16px"]}
              w="full"
              maxW={["165px", "230px"]}
              alignItems="center"
            >
              <Link
                isExternal
                href={"https://t.me/dragon_sui"}
                color="#ffffffb3"
                marginRight={"20px"}
                _hover={{ color: "#06A9B4" }}
              >
                <TelegramIcon
                  width={["20px", "32px"]}
                  height={["20px", "32px"]}
                />
              </Link>
              <Link
                isExternal
                href={"https://twitter.com/dragonsui_com"}
                color="#ffffffb3"
                _hover={{ color: "#06A9B4" }}
              >
                <TwitterIcon
                  width={["20px", "32px"]}
                  height={["20px", "32px"]}
                />
              </Link>
            </HStack>
          </Stack>

          {/* <Stack
            align={"flex-start"}
            pt={["16px", 0]}
            spacing={["6px", "10px"]}
          >
            <Heading pb={["0px", "20px"]} size="h4">
              Marketplace
            </Heading>

            <Link href={"#"}>
              <Text as="a" _hover={{ color: "#06A9B4" }}>
                Explore
              </Text>
            </Link>
            <Link href={"#"}>
              <Text as="a" _hover={{ color: "#06A9B4" }}>
                Articles
              </Text>
            </Link>
            <Link href={"#"}>
              <Text as="a" _hover={{ color: "#06A9B4" }}>
                How it Works
              </Text>
            </Link>
            <Link href={"#"}>
              <Text as="a" _hover={{ color: "#06A9B4" }}>
                Help
              </Text>
            </Link>
          </Stack>

          <Stack align={"flex-start"} spacing={["6px", "10px"]}>
            <Heading pb={["0px", "20px"]} size="h4">
              Links
            </Heading>

            <Link href={"#"}>
              <Text as="a" _hover={{ color: "#06A9B4" }}>
                Tokens
              </Text>
            </Link>
            <Link href={"#"}>
              <Text as="a" _hover={{ color: "#06A9B4" }}>
                API
              </Text>
            </Link>
            <Link href={"#"}>
              <Text as="a" _hover={{ color: "#06A9B4" }}>
                Big Bounty
              </Text>
            </Link>
            <Link href={"#"}>
              <Text as="a" _hover={{ color: "#06A9B4" }}>
                Become Partners
              </Text>
            </Link>
          </Stack> */}
        </SimpleGrid>
      </Flex>

      <HStack
        pl={["20px", "auto"]}
        justifyContent={["start", "center"]}
        pt={["0px", "40px"]}
        pb={["40px"]}
        w="full"
      >
        <Text fontSize="14px">
          © Copyright 2022 DragonSUI.
          <Show below="md">
            <br />
          </Show>{" "}
          All Rights Reserved
        </Text>
      </HStack>
    </VStack>
  );
};

export default Footer;
