import {
  Circle,
  Flex,
  Heading,
  Hide,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import React from "react";
import RocketIcon from "../theme/icon/RocketIcon";
import HourglassIcon from "../theme/icon/HourglassIcon";
import BrowserIcon from "../theme/icon/BrowserIcon";
import ThanhDinhViIcon from "../theme/icon/ThanhDinhViIcon";
import RoadmapBg from "../theme/images/roadmap-bg.png";
import RoadmapBgMobile from "../theme/images/roadmap-bg-mobile.png";

const Roadmap = () => {
  const RoadmapBgUrl = useBreakpointValue({
    base: RoadmapBgMobile,
    md: RoadmapBg,
  });

  return (
    <VStack textAlign="center" position="relative" pt={["160px", "194px"]} >
      <Flex
        w="full"
        h="full"
        zIndex="-1"
        top={["20px"]}
        position="absolute"
        align="center"
        id="Roadmap"
        overflow="hidden"
      >
        <Image
          w="full"
          h="full"
          src={RoadmapBgUrl}
          alt="RoadmapBgUrl"
          // objectFit="cover"
        />
      </Flex>
      <Stack
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="500"
        spacing="0"
      >
        <Heading size="h1">Roadmap</Heading>
        <Text
          pt="12px"
          maxW={["300px", "340px"]}
          color="fff"
          fontSize="lg"
          fontWeight="semibold"
          lineHeight={1.55}
        >
          DragonSUI launch will depend on SUI mainnet launch
        </Text>
      </Stack>

      <Flex
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="500"
        position="relative"
        w="full"
        maxW="1440px"
        mx="auto"
        px={["20px", "115px"]}
        pt={["46px", "60px"]}
        pb={["40px", "129px"]}
        flexDirection={["column", "column"]}
      >
        <SimpleGrid
          p={["0px", "12px"]}
          w="full"
          h="full"
          columns={[1, 3]}
          spacingX={["0px", "40px"]}
          spacingY={["20px", "0px"]}
        >
          <Flex
            _hover={{ transform: "translateY(-12px)" }}
            transition="all .6s cubic-bezier(.165,.84,.44,1);"
            minW="full"
            minH={["auto", "445px"]}
            alignItems="center"
            justifyContent={["start", "center"]}
            flexDirection="column"
            textAlign="center"
            pt={[0, "100px"]}
          >
            <Icon as={HourglassIcon} boxSize={14} color="blue.600" mb="5" />
            <Heading py="20px" size="h3" color="#06A9B4" fontWeight="bold">
              Q4 - 2022
            </Heading>

            <Stack justifyContent="center" pb="20px" spacing="4px">
              <HStack justifyContent="center">
                <Circle size="5px" bg="white"></Circle>
                <Text
                  maxW="340px"
                  color="#fff"
                  fontSize="lg"
                  fontWeight="semibold"
                  lineHeight={1.55}
                  _hover={{ color: "#06A9B4" }}
                >
                  First Airdrop Program{" "}
                </Text>
              </HStack>

              <HStack justifyContent="center">
                <Circle size="5px" bg="white"></Circle>{" "}
                <Text
                  maxW="340px"
                  color="#fff"
                  fontSize="lg"
                  fontWeight="semibold"
                  lineHeight={1.55}
                  _hover={{ color: "#06A9B4" }}
                >
                  Testnet Demo for Public Test{" "}
                </Text>
              </HStack>

              <HStack justifyContent="center">
                <Circle size="5px" bg="white"></Circle>{" "}
                <Text
                  maxW="340px"
                  color="#fff"
                  fontSize="lg"
                  fontWeight="semibold"
                  lineHeight={1.55}
                  _hover={{ color: "#06A9B4" }}
                >
                  Early Contribution Program{" "}
                </Text>
              </HStack>
            </Stack>

            <Hide below="md">
              <ThanhDinhViIcon />{" "}
            </Hide>
          </Flex>

          <Flex
            _hover={{ transform: "translateY(-12px)" }}
            transition="all .6s cubic-bezier(.165,.84,.44,1);"
            minW="full"
            minH={["auto", "445px"]}
            alignItems="center"
            justifyContent="start"
            flexDirection="column"
            textAlign="center"
          >
            <BrowserIcon />
            <Heading py="20px" size="h3" color="#06A9B4" fontWeight="bold">
              Q1 - 2023
            </Heading>

            <Stack justifyContent="center" pb="20px" spacing="4px">
              <HStack justifyContent="center">
                <Circle size="5px" bg="white"></Circle>
                <Text
                  maxW="340px"
                  color="#fff"
                  fontSize="lg"
                  fontWeight="semibold"
                  lineHeight={1.55}
                  _hover={{ color: "#06A9B4" }}
                >
                  Bug Bounty Program{" "}
                </Text>
              </HStack>

              <HStack justifyContent="center">
                <Circle size="5px" bg="white"></Circle>
                <Text
                  maxW="340px"
                  color="#fff"
                  fontSize="lg"
                  fontWeight="semibold"
                  lineHeight={1.55}
                  _hover={{ color: "#06A9B4" }}
                >
                  Code Audit and Review{" "}
                </Text>
              </HStack>

              <HStack justifyContent="center">
                <Circle size="5px" bg="white"></Circle>
                <Text
                  maxW="340px"
                  color="#fff"
                  fontSize="lg"
                  fontWeight="semibold"
                  lineHeight={1.55}
                  _hover={{ color: "#06A9B4" }}
                >
                  Validator Node{" "}
                </Text>
              </HStack>
            </Stack>

            <Hide below="md">
              <ThanhDinhViIcon />{" "}
            </Hide>
          </Flex>

          <Flex
            _hover={{ transform: "translateY(-12px)" }}
            transition="all .6s cubic-bezier(.165,.84,.44,1);"
            minW="full"
            minH={["auto", "445px"]}
            alignItems="center"
            justifyContent={["start", "center"]}
            flexDirection="column"
            textAlign="center"
            pt={[0, "100px"]}
          >
            <Icon as={RocketIcon} boxSize={14} color="blue.600" mb="5" />
            <Heading py="20px" size="h3" color="#06A9B4" fontWeight="bold">
              Q2 - 2023
            </Heading>

            <Stack justifyContent="center" pb="20px" spacing="4px">
              <HStack justifyContent="center">
                <Circle size="5px" bg="white"></Circle>
                <Text
                  maxW="340px"
                  color="#fff"
                  fontSize="lg"
                  fontWeight="semibold"
                  lineHeight={1.55}
                  _hover={{ color: "#06A9B4" }}
                >
                  Mainnet Launch{" "}
                </Text>
              </HStack>

              <HStack justifyContent="center">
                <Circle size="5px" bg="white"></Circle>{" "}
                <Text
                  maxW="340px"
                  color="#fff"
                  fontSize="lg"
                  fontWeight="semibold"
                  lineHeight={1.55}
                  _hover={{ color: "#06A9B4" }}
                >
                  Multi-chain support{" "}
                </Text>
              </HStack>
            </Stack>

            <Hide below="md">
              <ThanhDinhViIcon />{" "}
            </Hide>
          </Flex>
        </SimpleGrid>
      </Flex>
    </VStack>
  );
};

export default Roadmap;
