import { Icon } from "@chakra-ui/react";

export default function TelegramIcon({
  color = "#06A9B4",
  width = "19px",
  height = "19px",
}) {
  return (
    <Icon width={width} height={height} viewBox="0 0 19 19" fill="none">
      <path
        d="M18.4219 2.36719C18.6562 1.27344 18.0312 0.84375 17.3281 1.11719L1.78125 7.09375C0.726562 7.52344 0.765625 8.10938 1.625 8.38281L5.57031 9.59375L14.7891 3.8125C15.2188 3.5 15.6484 3.69531 15.2969 3.96875L7.83594 10.6875L7.5625 14.7891C7.99219 14.7891 8.14844 14.6328 8.38281 14.3984L10.2969 12.5234L14.3203 15.4922C15.0625 15.9219 15.6094 15.6875 15.8047 14.8281L18.4219 2.36719Z"
        fill="currentColor"
      />
    </Icon>
  );
}
