import React, { useCallback } from "react";
import { Box, Image, Flex, useBreakpointValue, VStack } from "@chakra-ui/react";

import Roadmap from "./components/Roadmap";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Hero2 from "./components/Hero2";
import NavBar from "./components/NavBar";
import MaskGroup01 from "./theme/images/mask-group-01.png";
import MaskGroup01Mobile from "./theme/images/mask-group-01-mobile.png";
import TokenTeamAdvisor from "./components/TokenTeamAdvisor";
import "./style.css"

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function App() {
  const particlesInit = useCallback(async (main) => {
    await loadFull(main);
  }, []);

  document.title = "DragonSUI - NFT Marketplace on SUI blockchain"

  const MaskGroupURL = useBreakpointValue({
    base: MaskGroup01Mobile,
    md: MaskGroup01,
  });

  return (
    <Box>
      <Particles
        canvasClassName="tsparticles-canvas"
        id="tsparticles"
        init={particlesInit}
        options={options1}
      />
      <VStack position="relative">
        <Flex
          top="0"
          right="0"
          zIndex="-1"
          align="center"
          overflow="hidden"
          position="absolute"
        >
          <Image
            w="full"
            h="full"
            src={MaskGroupURL}
            alt="MaskGroup01"
            objectFit="cover"
          />
        </Flex>

        <NavBar />
        <Hero />
      </VStack>

      <Hero2 />
      <Roadmap />
      <TokenTeamAdvisor />
      <Footer />
    </Box>
  );
}

export default App;

const options1 = {
  fpsLimit: 480,
  interactivity: {
    detectsOn: "canvas",
    events: {
      resize: true,
    },
  },
  particles: {
    color: {
      value: "#ffffff",
    },

    collisions: {
      enable: true,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {
        default: "bounce",
      },
      random: false,
      speed: 0.1,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        area: 1080,
      },
      limit: 0,
      value: 40,
    },
    opacity: {
      value: 1,
      animation: {
        enable: true,
        minimumValue: 0.5,
        speed: 0.1,
        sync: false,
      },
      random: {
        enable: true,
        minimumValue: 0.1,
      },
    },
    shape: {
      type: "circle",
    },
    size: {
      random: {
        enable: true,
        minimumValue: 0.5,
      },
      value: 2,
    },
  },
  detectRetina: true,
  fullScreen: { enable: true },
};
