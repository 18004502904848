import React, {  } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import { Flex } from "@chakra-ui/react";

import { ADVISOR_LIST, TEAM_LIST } from "./TokenTeamAdvisor";
import CoreTeamCard from "./Card/CoreTeamCard";
import AdvisorCard from "./Card/AdvisorCard";

export function TeamSwiperMobile() {
  return (
    <Flex
      data-aos="fade-up"
      data-aos-delay="100"
      data-aos-duration="500"
      pl="5px"
      pt="25px"
      minH="375px"
      maxW="800px"
      w="full"
    >
      <Swiper
        style={{
          paddingTop: "15px",
          paddingLeft: "15px",
        }}
        spaceBetween={20}
        slidesPerView={1.7}
        className="mySwiper"
        modules={[Pagination]}
        pagination={{ clickable: true }}
      >
        {TEAM_LIST.map((item, idx) => (
          <SwiperSlide pt="25px" key={idx} w={["200px"]}>
            <CoreTeamCard idx={`team-${idx}`} {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Flex>
  );
}

export function AdvisorSwiperMobile() {
  return (
    <Flex
      data-aos="fade-up"
      data-aos-delay="100"
      data-aos-duration="500"
      pl="5px"
      pt="25px"
      minH="510px"
      maxW="800px"
      w="full"
    >
      <Swiper
        style={{
          paddingTop: "15px",
          paddingLeft: "15px",
        }}
        spaceBetween={20}
        slidesPerView={1.7}
        className="mySwiper"
        modules={[Pagination]}
        pagination={{ clickable: true }}
      >
        {ADVISOR_LIST.map((item, idx) => (
          <SwiperSlide key={idx} w={["200px"]}>
            <AdvisorCard idx={`advisor-${idx}`} {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Flex>
  );
}
