import { Icon } from "@chakra-ui/react";

export default function ChartCircle({
  color = "#06A9B4",
  width = "390px",
  height = "390px",
}) {
  return (
    <Icon
      className="lock-icon"
      width={width}
      height={height}
      viewBox="0 0 390 390"
      fill="none"
    >
      <path
        d="M196.168 195.173 12.383 138.246C37.562 57.13 108.572 4.121 193.583 2.978l2.585 192.195Z"
        fill="#2925EE"
      />
      <path
        d="M196.168 195.173 85.171 352.171c-69.45-48.991-97.985-132.827-72.788-213.925l183.785 56.927Z"
        fill="#9448F0"
      />
      <path
        d="M196.169 195.173 379.954 252.1C348.475 353.491 240.676 410.188 139.17 378.748c-20.298-6.291-36.64-14.339-53.999-26.578L196.17 195.173Z"
        fill="#6338DB"
      />
      <path
        d="m196.168 195.173 182.188-61.849c13.685 40.196 14.196 78.227 1.597 118.776l-183.785-56.927Z"
        fill="#CE30DD"
      />
      <path
        d="M196.168 195.173 307.183 38.157c34.717 24.496 57.506 54.953 71.191 95.149l-182.188 61.849-.018.018Z"
        fill="#46EACC"
      />
      <path
        d="M196.169 195.173 193.584 2.978c42.515-.572 78.865 10.684 113.581 35.18L196.168 195.155l.001.018Z"
        fill="#06A9B4"
      />
      <path
        d="M309.907 193.661c.845 62.811-49.389 114.414-112.2 115.259-62.811.844-114.414-49.389-115.259-112.2-.844-62.811 49.389-114.414 112.2-115.259 62.811-.845 114.414 49.389 115.259 112.2Z"
        fill="#000"
      />
    </Icon>
  );
}
