import { Icon } from "@chakra-ui/react";

export default function ThanhDinhViIcon({
  color = "#06A9B4",
  width = "20px",
  height = "100px",
}) {
  return (
    <Icon
      className="lock-icon"
      width={width}
      height={height}
      viewBox="0 0 20 100"
      fill="none"
    >
      <path fill="#fff" d="M9 0h1.033v92.943H9z" />
      <path
        d="M9.816 98.513 11.609 101l.903-2.93 2.566 1.666-.214-3.057 2.996.628-1.302-2.777 3.02-.498-2.216-2.118L20 90.356l-2.829-1.171 1.895-2.409-3.057-.068.9-2.93-2.88 1.041-.219-3.06-2.307 2.015L10.198 81l-1.425 2.712-2.215-2.11-.351 3.043-2.83-1.168.77 2.968-3.06-.065 1.79 2.49L0 89.915l2.563 1.674L.26 93.608l2.996.629-1.421 2.715 3.02-.495-.348 3.047 2.638-1.554.774 2.968 1.898-2.405Z"
        fill="#06A9B4"
      />
    </Icon>
  );
}
