import {
  Flex,
  Heading,
  HStack,
  Image,
  Show,
  SimpleGrid,
  Spacer,
  Square,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

import ChartCircle from "../theme/icon/ChartCircle";
import ChartCircle1 from "../theme/svg/tokenomics-chart1.svg";
import ChartCircle2 from "../theme/svg/tokenomics-chart2.svg";
import ChartCircle3 from "../theme/svg/tokenomics-chart3.svg";
import ChartCircle4 from "../theme/svg/tokenomics-chart4.svg";
import ChartCircle5 from "../theme/svg/tokenomics-chart5.svg";
import ChartCircle6 from "../theme/svg/tokenomics-chart6.svg";
import TokenTeamAndAdvisorBg from "../theme/images/token-team-advisor-bg.png";
import TokenTeamAndAdvisorBgMobile from "../theme/images/token-team-advisor-bg-mobile.png";
import TeamTerry from "../theme/images/team-terry-vu.png";
import TeamCNguyen from "../theme/images/team-c-nguyen.png";
import TeamNick from "../theme/images/team-nick-hoang.png";
import TeamTienDoan from "../theme/images/team-tien-doan.png";
import TeamTian from "../theme/images/team-tian-ng.png";
import TeamThao from "../theme/images/team-thao-tran.png";
import TeamLy from "../theme/images/team-ly-nguyen.png";
import AdvisorBrian from "../theme/images/advisor-brian.png";
import AdvisorHieu from "../theme/images/advisor-hieu.png";
import AdvisorFrankie from "../theme/images/advisor-frankie.png";
import { AdvisorSwiperMobile, TeamSwiperMobile } from "./SwiperMobile";
import CoreTeamCard from "./Card/CoreTeamCard";
import AdvisorCard from "./Card/AdvisorCard";
import TokenEChart from "./TokenEChart";

const TokenTeamAdvisor = () => {
  const TokenTeamAndAdvisorBgUrl = useBreakpointValue({
    base: TokenTeamAndAdvisorBgMobile,
    md: TokenTeamAndAdvisorBg,
  });

  return (
    <VStack
      textAlign="center"
      position="relative"
      pt={["56px", "142px"]}
      pb="30px"
    >
      <Flex
        w="full"
        h="full"
        zIndex="-1"
        top={["-60px"]}
        align="center"
        overflow="hidden"
        position="absolute"
      >
        <Image
          w="full"
          h="full"
          src={TokenTeamAndAdvisorBgUrl}
          alt="TokenTeamAndAdvisorBgUrl"
          // objectFit="cover"
        />
      </Flex>

      {/* Tokenomics */}

      <Stack
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="500"
        spacing="0px"
        id="Tokenomics"
      >
        <Heading size="h1">Tokenomics</Heading>
        <Text
          pt="12px"
          maxW="340px"
          color="fff"
          fontSize="lg"
          fontWeight="semibold"
          lineHeight={1.55}
        >
          Passive growth for token holders
        </Text>
      </Stack>

      <Flex
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="500"
        w="full"
        maxW="1400px"
        px={["20px", "115px"]}
        pt={["0px", "160px"]}
        flexDirection={["column", "row"]}
      >
        <Flex ml={[0, "-60px"]} w="full" py={["26px", 0]} overflow="hidden">
          <TokenEChart />
        </Flex>

        <Flex
          hidden
          w={["full", "55%"]}
          alignItems="start"
          justifyContent="center"
        >
          <Square position="relative">
            <ChartCircle width={["285px", "385px"]} />

            <Stack position={"absolute"} left="0" right="0" mx="auto" w="145px">
              <Heading fontSize={["18px", "20px"]}>Total Supply</Heading>
              <Heading fontSize={["32px", "50px"]} color="#06A9B4">
                100M
              </Heading>
            </Stack>

            {/* DESKTOP Stats */}

            {/* ChartCircle 01 */}
            <Stack
              top="-70px"
              right="-30px"
              maxW="134px"
              textAlign="left"
              position={["absolute"]}
              display={["none", "flex"]}
              _before={{
                bottom: "-54px",
                left: "-32px",
                width: "174px",
                height: "52px",
                position: "absolute",
                content: `url(${ChartCircle1})`,
              }}
            >
              <Text position="relative" fontSize="md" color="#fff">
                Airdrop/Bounty/ Partners:{" "}
                <Text as="span" color="#06A9B4">
                  10M
                </Text>
              </Text>
            </Stack>

            {/* ChartCircle 02 */}
            <Stack
              top="25px"
              right="-160px"
              maxW="186px"
              textAlign="left"
              position="absolute"
              display={["none", "flex"]}
              _before={{
                bottom: "-54px",
                left: "-48px",
                height: "52px",
                position: "absolute",
                content: `url(${ChartCircle2})`,
              }}
            >
              <Text fontSize="md" color="#fff">
                Early Contributors:{" "}
                <Text as="span" color="#06A9B4">
                  10M
                </Text>
              </Text>
            </Stack>

            {/* ChartCircle 03 */}
            <Stack
              bottom="50%"
              right="-160px"
              maxW="163px"
              textAlign="left"
              position="absolute"
              display={["none", "flex"]}
              _before={{
                bottom: "-50px",
                left: "-60px",
                height: "52px",
                position: "absolute",
                content: `url(${ChartCircle3})`,
              }}
            >
              <Text fontSize="md" color="#fff">
                Private Sale:{" "}
                <Text as="span" color="#06A9B4">
                  10M
                </Text>
              </Text>
            </Stack>

            {/* ChartCircle 04 */}
            <Stack
              bottom="24px"
              right="-80px"
              maxW="163px"
              textAlign="left"
              position="absolute"
              display={["none", "flex"]}
              _before={{
                bottom: "-14px",
                left: "-78px",
                height: "52px",
                position: "absolute",
                content: `url(${ChartCircle4})`,
              }}
            >
              <Text fontSize="md" color="#fff">
                Public Sale:{" "}
                <Text as="span" color="#06A9B4">
                  30M
                </Text>
              </Text>
            </Stack>

            {/* ChartCircle 05 */}
            <Stack
              bottom="0"
              top="0"
              my="auto"
              h="24px"
              left="-130px"
              maxW="163px"
              textAlign="left"
              position="absolute"
              display={["none", "flex"]}
              _before={{
                bottom: "-10px",
                left: "-12px",
                width: "174px",
                height: "12px",
                position: "absolute",
                content: `url(${ChartCircle5})`,
              }}
            >
              <Text fontSize="md" color="#fff">
                Team:{" "}
                <Text as="span" color="#06A9B4">
                  20M
                </Text>
              </Text>
            </Stack>

            {/* ChartCircle 06 */}
            <Stack
              top="-30px"
              left="-120px"
              maxW="163px"
              textAlign="left"
              position="absolute"
              display={["none", "flex"]}
              _before={{
                bottom: "-58px",
                right: "-50px",
                height: "52px",
                position: "absolute",
                content: `url(${ChartCircle6})`,
              }}
            >
              <Text fontSize="md" color="#fff">
                Ecosystem Building & Reserve Pool:{" "}
                <Text as="span" color="#06A9B4">
                  20M
                </Text>
              </Text>
            </Stack>
          </Square>
        </Flex>

        {/* Mobile Stats */}
        <Stack spacing="16px" display={["flex", "none"]} pb="60px">
          {/* ChartCircle 01 */}
          <HStack textAlign="left" alignItems="start">
            <Square mr="4px" size="24px" bg="#06A9B4"></Square>
            <Text fontSize="18px" color="#fff">
              Airdrop/Bounty/ Partners:{" "}
              <Text as="span" color="#06A9B4" fontSize="18px">
                10M
              </Text>
            </Text>
          </HStack>

          {/* ChartCircle 02 */}
          <HStack textAlign="left" alignItems="start">
            <Square mr="4px" size="24px" bg="#46EACC"></Square>
            <Text fontSize="18px" color="#fff">
              Early Contributors:{" "}
              <Text as="span" color="#06A9B4" fontSize="18px">
                10M
              </Text>
            </Text>
          </HStack>

          {/* ChartCircle 03 */}
          <HStack textAlign="left" alignItems="start">
            <Square mr="4px" size="24px" bg="#CE30DD"></Square>
            <Text fontSize="18px" color="#fff">
              Private Sale:{" "}
              <Text as="span" color="#06A9B4" fontSize="18px">
                10M
              </Text>
            </Text>
          </HStack>

          {/* ChartCircle 04 */}
          <HStack textAlign="left" alignItems="start">
            <Square mr="4px" size="24px" bg="#6338DB"></Square>
            <Text fontSize="18px" color="#fff">
              Public Sale:{" "}
              <Text as="span" color="#06A9B4" fontSize="18px">
                30M
              </Text>
            </Text>
          </HStack>

          {/* ChartCircle 05 */}
          <HStack textAlign="left" alignItems="start">
            <Square mr="4px" size="24px" bg="#9448F0"></Square>
            <Text fontSize="18px" color="#fff">
              Team:{" "}
              <Text as="span" color="#06A9B4" fontSize="18px">
                20M
              </Text>
            </Text>
          </HStack>

          {/* ChartCircle 06 */}
          <HStack textAlign="left" alignItems="start">
            <Square mr="4px" size="24px" bg="#2925EE"></Square>
            <Text fontSize="18px" color="#fff">
              Ecosystem Building & Reserve Pool:{" "}
              <Text as="span" color="#06A9B4" fontSize="18px">
                20M
              </Text>
            </Text>
          </HStack>
        </Stack>

        <Spacer />

        <Stack
          m="auto"
          spacing="25px"
          bg="#080E09"
          maxW="372px"
          px={["20px", "40px"]}
          pt={["32px", "44px"]}
          pb={["38px", "55px"]}
          minW={["330px", "372px"]}
          minH={["290px", "320px"]}
          border="2px solid #06A9B4"
          justifyContent={"center"}
          textAlign={["center", "left"]}
        >
          <HStack spacing="18px" alignItems="center">
            <Heading color="#06A9B4" fontSize="50px" maxW="112px">
              30%
            </Heading>
            <Text textAlign="left" color="white" maxW="160px">
              profit of the platform{" "}
            </Text>
          </HStack>
          <HStack spacing="18px" alignItems="center">
            <Heading color="#9448F0" fontSize="50px" maxW="112px">
              50%
            </Heading>
            <Text color="white" maxW="160px" textAlign="left">
              profit from running SUI Validator Node
            </Text>
          </HStack>
          <HStack spacing="18px">
            <Text color="white" textAlign="right">
              will be used to buyback DRA tokens
            </Text>
          </HStack>
        </Stack>
      </Flex>

      {/* TEAMS SECTION */}

      <Stack
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="500"
        spacing="0px"
        pt={["58px", "178px"]}
        id="TEAM & ADVISORS"
      >
        <Heading size="h1">Core team</Heading>
        <Text
          pt="12px"
          maxW="340px"
          color="fff"
          fontSize="lg"
          fontWeight="semibold"
          lineHeight={1.55}
        >
          We believe in SUI{" "}
        </Text>
      </Stack>

      <Show
        below="md"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="500"
      >
        <TeamSwiperMobile />
      </Show>

      <Show above="sm">
        <Flex
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="500"
          justifyContent="center"
          maxW="1440px"
          w="full"
          px="136px"
          pt={["40px", "70px"]}
        >
          <Swiper
            style={{
              paddingTop: "15px",
              paddingLeft: "15px",
            }}
            spaceBetween={20}
            slidesPerView={5}
            slidesPerGroup={2}
            className="mySwiper team-swiper"
            modules={[Pagination]}
            pagination={{ clickable: true }}
          >
            {TEAM_LIST.map((item, idx) => (
              <SwiperSlide key={idx} w={["200px"]}>
                <CoreTeamCard idx={`team-${idx}`} {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>
      </Show>

      {/* ADVISOR SECTION */}

      <Stack
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="500"
        spacing="0px"
        pt={["44px", "162px"]}
      >
        <Heading size="h1">Advisors</Heading>
        <Text
          pt="20px"
          maxW={["320px", "430px"]}
          color="fff"
          fontSize="lg"
          fontWeight="semibold"
          lineHeight={1.55}
        >
          Professional Advices from our business friends{" "}
        </Text>
      </Stack>

      <Show
        below="md"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="500"
      >
        <AdvisorSwiperMobile />
      </Show>

      <Show above="sm">
        <Flex
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="500"
          justifyContent="center"
          w="full"
          px="136px"
          pt={["40px", "56px"]}
        >
          <SimpleGrid
            maxW="920px"
            w="full"
            h="full"
            columns={[1, 3]}
            spacingX={["0px", "42.5px"]}
            spacingY={["20px", "20px"]}
          >
            {ADVISOR_LIST.map((item, idx) => (
              <Fragment key={idx}>
                <AdvisorCard idx={`advisor-${idx}`} {...item} />
              </Fragment>
            ))}
          </SimpleGrid>
        </Flex>
      </Show>
    </VStack>
  );
};

export default TokenTeamAdvisor;

export const TEAM_LIST = [
  {
    name: "Mitch Doan",
    position: "Business Developer",
    avatar: TeamTienDoan,
    twitter: "https://twitter.com",
    telegram: "https://telegram.com",
    linkedin: "https://sg.linkedin.com/in/tien-doan",
  },
  {
    name: "Terry Vu",
    position: "DevOps",
    avatar: TeamTerry,
    twitter: "https://twitter.com",
    telegram: "https://telegram.com",
    linkedin: "https://www.linkedin.com/in/vup2p/",
  },
  {
    name: "C Nguyen",
    position: "Backend Dev",
    avatar: TeamCNguyen,
    twitter: "https://twitter.com",
    telegram: "https://telegram.com",
    linkedin: "https://www.linkedin.com/in/seteda-charles-89b634252/",
  },
  {
    name: "Nick Hoang",
    position: "Full-stack Dev",
    avatar: TeamNick,
    twitter: "https://twitter.com",
    telegram: "https://telegram.com",
    linkedin: "https://www.linkedin.com/in/nam-hoang-7a696a214/",
  },
  {
    name: "Thao Tran",
    position: "UI/UX Designer",
    avatar: TeamThao,
    twitter: "https://twitter.com",
    telegram: "https://telegram.com",
    linkedin: "https://www.linkedin.com/in/thao-tran-714b0822b/",
  },
  {
    name: "Tian Nguyen",
    position: "Frontend Dev",
    avatar: TeamTian,
    twitter: "https://twitter.com",
    telegram: "https://telegram.com",
    linkedin: "https://www.linkedin.com/in/thien-nguyen-van/",
  },
  {
    name: "Ly Nguyen",
    position: "Graphic Designer",
    avatar: TeamLy,
    twitter: "https://twitter.com",
    telegram: "https://telegram.com",
    linkedin: "https://www.linkedin.com/in/dieu-ly-nguyen-1050421a2/",
  },
];

export const ADVISOR_LIST = [
  {
    name: "Brian Nguyen",
    position: (
      <div>
        Co-Founder{" "}
        <a href="http://artzero.io/" target="_blank">
          Artzero.io
        </a>{" "}
      </div>
    ),
    avatar: AdvisorBrian,
    description:
      "Choose the NFT you want to sell and we help you sell them. buyers can bid for the NFT",
    linkedin: "https://www.linkedin.com/in/nad128668/",
  },
  {
    name: "Hieu Dao",
    position: (
      <div>
        Co-Founder{" "}
        <a href="https://subwallet.app/" target="_blank">
          SubWallet
        </a>{" "}
      </div>
    ),
    avatar: AdvisorHieu,
    description:
      "Choose the NFT you want to sell and we help you sell them. buyers can bid for the NFT",
  },

  {
    name: "Frankie",
    position: "Art Director",
    avatar: AdvisorFrankie,
    description:
      "Choose the NFT you want to sell and we help you sell them. buyers can bid for the NFT",
  },
];
