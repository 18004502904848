import { Icon } from "@chakra-ui/react";

export default function HourglassIcon({
  color = "#06A9B4",
  width = "56px",
  height = "56px",
}) {
  return (
    <Icon
      className="lock-icon"
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
    >
      <path
        opacity=".2"
        d="m28 28-13.3-9.975a1.773 1.773 0 0 1-.7-1.4V14h28v2.538a1.773 1.773 0 0 1-.7 1.4L28 28Z"
        fill="#9448F0"
      />
      <path
        d="M14 14h28M28 28l-13.3-9.975a1.773 1.773 0 0 1-.7-1.4V8.75A1.75 1.75 0 0 1 15.75 7h24.5A1.75 1.75 0 0 1 42 8.75v7.788a1.773 1.773 0 0 1-.7 1.4L28 28Zm0 0-13.3 9.975a1.773 1.773 0 0 0-.7 1.4v7.875A1.75 1.75 0 0 0 15.75 49h24.5A1.75 1.75 0 0 0 42 47.25v-7.788a1.773 1.773 0 0 0-.7-1.4L28 28Z"
        stroke="#9448F0"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Icon>
  );
}
