import { Icon } from "@chakra-ui/react";

export default function TeamIcon({
  color = "#06A9B4",
  width = "128px",
  height = "128px",
  xlinkHref,
  idx,
}) {
  return (
    <Icon
      id={idx}
      width={width}
      height={height}
      viewBox="0 0 128 128"
      fill="none"
    >
      <mask
        id={`mask_${idx}`}
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="120"
        height="120"
      >
        <path
          d="M64 0C109.497 0 124 14.5026 124 60C124 105.497 109.497 120 64 120C18.5026 120 4 105.497 4 60C4 14.5026 18.5026 0 64 0Z"
          fill="#F0F4FF"
        />
      </mask>
      <g mask={`url(#mask_${idx})`}>
        <rect
          x="-2"
          y="-6"
          width="130.8"
          height="130.8"
          fill={`url(#pattern_${idx})`}
        />
        <g style={{ mixBlendMode: "screen" }}>
          <path
            d="M64 0C109.497 0 124 14.5026 124 60C124 105.497 109.497 120 64 120C18.5026 120 4 105.497 4 60C4 14.5026 18.5026 0 64 0Z"
            fill="#040818"
          />
        </g>
      </g>
      <defs>
        <pattern
          id={`pattern_${idx}`}
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref={`#image_${idx}`} transform="scale(0.00666667)" />
        </pattern>
        <image
          id={`image_${idx}`}
          width="150"
          height="150"
          xlinkHref={xlinkHref}
        />
      </defs>
    </Icon>
  );
}
