import { Icon } from "@chakra-ui/react";

export default function BrowserIcon({
  color = "#06A9B4",
  width = "56px",
  height = "56px",
}) {
  return (
    <Icon
      className="lock-icon"
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
    >
      <path
        opacity=".2"
        d="M47.75 10.5h-31.5a1.75 1.75 0 0 0-1.75 1.75v5.25h26.25a1.75 1.75 0 0 1 1.75 1.75V38.5h5.25a1.75 1.75 0 0 0 1.75-1.75v-24.5a1.75 1.75 0 0 0-1.75-1.75Z"
        fill="#9448F0"
      />
      <path
        d="M40.75 17.5H9.25a1.75 1.75 0 0 0-1.75 1.75v24.5c0 .967.784 1.75 1.75 1.75h31.5a1.75 1.75 0 0 0 1.75-1.75v-24.5a1.75 1.75 0 0 0-1.75-1.75Z"
        stroke="#9448F0"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5 17.5v-5.25a1.75 1.75 0 0 1 1.75-1.75h31.5a1.75 1.75 0 0 1 1.75 1.75v24.5a1.75 1.75 0 0 1-1.75 1.75H42.5m-35-14h35"
        stroke="#9448F0"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Icon>
  );
}
