import { Flex, Heading, HStack, Link, Stack, Text } from "@chakra-ui/react";
import React from "react";
import TeamIcon from "../../theme/icon/TeamIcon";
import LinkedInIcon from "../../theme/icon/Linkedin-icon";

function CoreTeamCard({idx, name, avatar, position, linkedin, telegram }) {
  console.log('idx', idx)
  
  return (
    <Flex w="200px" border="2px solid #06A9B4">
      <Flex
        _hover={{
          border: "2px solid #06A9B4",
          transform: "translate(-12px, -12px)",
        }}
        bg="#000"
        px={["16px"]}
        w={["196px"]}
        minH={["274px"]}
        pt={["0", "12px"]}
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        flexDirection={["column"]}
        border="2px solid #06A9B400"
        transition="all .6s cubic-bezier(.165,.84,.44,1);"
      >
        <TeamIcon idx={idx} xlinkHref={`${avatar}`} />

        <Stack spacing="6px" pt={["16px"]}>
          <Heading fontSize={["18px", "20px"]}>{name}</Heading>

          <Text color="#fff6">{position}</Text>

          <HStack justifyContent="center">
            <Link
              isExternal
              href={linkedin}
              color="#06A9B4"
              _hover={{ color: "#fff" }}
            >
              <LinkedInIcon />
            </Link>
          </HStack>
        </Stack>
      </Flex>
    </Flex>
  );
}

export default CoreTeamCard;
